import { axiosInstance } from 'services/axiosConfig';
import { ResponseError } from '../models';
import { AttachmentPath, FileSize } from '../constants';
import { handleCheckSum, handleFileExtension } from './utils';

const createAttachmentObject = async (payload, metadata, path, generatedFileName) => ({
  ...metadata,
  checksum: await handleCheckSum(payload),
  sizeInBytes: payload.size,
  extension: handleFileExtension(payload.name),
  originalName: payload.name,
  path,
  generatedFileName,
});

export const generatePreSignedUrl = async ({ payload, metadata, options = {}, fileMaxSize }) => {
  if (payload === null || payload === undefined) {
    throw new ResponseError('payload', {
      error: 'Required parameter payload was null or undefined when calling createNewAttachmentForm',
    });
  }
  if (metadata === null || metadata === undefined) {
    throw new ResponseError('metadata', {
      error: 'Required parameter metadata was null or undefined when calling createNewAttachmentForm',
    });
  }
  if (fileMaxSize === null || fileMaxSize === undefined) {
    throw new ResponseError('fileMaxSize', {
      error: 'Required parameter fileMaxSize was null or undefined when calling createNewAttachmentForm',
    });
  }
  if (payload.size > fileMaxSize * FileSize.MB) {
    throw new ResponseError('fileMaxSize', {
      status: 413,
    });
  }

  const attachmentObject = await createAttachmentObject(payload, metadata);
  if (metadata.subResourceType === 'DESCRIPTION') {
    metadata.subResourceId = metadata.resourceId;
    metadata.subResourceType = metadata.resourceType;
  }
  return axiosInstance
    .post(`/${AttachmentPath.V2}generate_presigned_url`, attachmentObject, {
      ...options,
    })
    .then((response) => response.data);
};

export const updateAttachmentInS3 = async (payload, preSignedUrl) =>
  axiosInstance.put(preSignedUrl, payload).then((response) => response);

export const updateInDB = async ({ payload, metadata, options = {}, path, generatedFileName }) => {
  const attachmentObject = await createAttachmentObject(payload, metadata, path, generatedFileName);
  return axiosInstance
    .post(`/${AttachmentPath.V2}`, attachmentObject, {
      ...options,
    })
    .then((response) => response.data);
};

export const updateMultipleInDB = async (filesMetadata) => {
  const requestPromises = filesMetadata.map(async (fileMetadata) =>
    createAttachmentObject(
      fileMetadata.params.payload,
      fileMetadata.params.metadata,
      fileMetadata.preSignedUrl?.path,
      fileMetadata.preSignedUrl?.generatedFileName,
    ),
  );
  const request = await Promise.all(requestPromises);
  return axiosInstance.post(`/${AttachmentPath.V2}multiple`, request).then((response) => response.data);
};

export const getPresignedUrl = async (attachmentId) =>
  axiosInstance.get(`/${AttachmentPath.V2}${attachmentId}/generate_presigned_url`).then((response) => response.data);

export const getPreviewPresignedUrl = async (attachmentId) =>
  axiosInstance
    .get(`/${AttachmentPath.V2}${attachmentId}/generate_presigned_url?inline=true`)
    .then((response) => response.data);

import { Box } from '@mui/material';
import checkedOffIcon from 'images/icons/checkedOff.svg';
import checkedOnIcon from 'images/icons/checkedOn.svg';
import checkedIndeterminateIcon from 'images/icons/checkedIndeterminate.svg';
import { styled } from '@mui/material/styles';

export const StyledGridContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
}));

export const StyledGridStyle = styled(Box)(() => ({
  width: '100%',
  height: '100%',
}));

export const StyledGrid = styled(Box, {
  shouldForwardProp: (prop) =>
    !['showFilters', 'isLastRowWithData', 'columnHeaderClass', 'isModal', 'isSROpenedFromQueue', 'isScrolled'].includes(
      prop,
    ),
})(({ theme, isLastRowWithData, isSROpenedFromQueue, isScrolled }) => ({
  '.ag-paging-panel': {
    '.ag-paging-description': {
      display: 'none',
    },
    '[ref=btFirst]': {
      display: 'none',
    },
    '[ref=btLast]': {
      display: 'none',
    },
    '[ref=lbRecordCount]': {
      display: 'none',
    },
  },
  '.ag-selection-checkbox': {
    display: 'flex',
    '.ag-checkbox-input-wrapper': {
      width: '15px',
      height: '15px',
      background: `transparent  url(${checkedOffIcon}) center/contain no-repeat`,
      color: 'transparent',
      '&.ag-checked': {
        background: `transparent  url(${checkedOnIcon}) center/contain no-repeat`,
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  'span.ag-cell-value': {
    position: 'absolute',
    top: '13px',
  },
  '.ag-selection-checkbox-hide': {
    'span.ag-cell-value': {
      opacity: '1',
    },
  },
  '& ::-webkit-scrollbar': {
    width: '26px',
    height: '26px',

    '&-track': {
      visibility: 'hidden',
    },

    '&-thumb': {
      backgroundColor: theme.palette.grey.bt_alt_grey_hover,
      borderRadius: '20px',
      height: '500px',
      border: '11px solid #fff',
      '&:hover': {
        backgroundColor: theme.palette.grey.bt_def_grey_light,
      },
    },
  },
  '@supports (scrollbar-color: #e1e2e5 #fff)': {
    '& .ag-body-viewport, & .ag-body-horizontal-scroll-viewport': {
      scrollbarColor: `${theme.palette.grey.bt_alt_grey_hover} ${theme.palette.generic.primary}`,
      scrollbarWidth: 'thin',
      scrollbarGutter: 'stable',
      height: '24px!important',
    },
  },

  '.ag-body-horizontal-scroll.ag-scrollbar-invisible': {
    pointerEvents: 'inherit',
    scrollbarGutter: 'stable',
  },
  '& .shadow-inset-center': {
    webkitAnimation: 'shadow-inset-center 1s ease-in-out reverse both',
    animation: 'shadow-inset-center 1s ease-in-out reverse both',
  },

  '& .ag-cell': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    lineHeight: 'normal',
    //align-content: center,
    /* margin-left: 15px, */
  },

  '& .ag-root-wrapper': {
    borderRadius: '0 0 10px 10px',
    border: 'none',
  },

  '& .ag-root': {
    // paddingBottom: '8px',
  },

  '& .ag-horizontal-right-spacer.ag-scroller-corner': {
    overflowX: 'visible',
  },

  '& .ag-theme-alpine .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner)':
    {
      borderRight: 'none',
    },

  '& .ag-horizontal-left-spacer': {
    overflowX: 'visible',
  },

  '& .ag-theme-alpine .ag-body-horizontal-scroll': {
    overflowX: 'visible',
    height: '28px!important',
    maxHeight: '28px!important',
    minHeight: '28px!important',
    scrollbarGutter: 'stable',
    '.ag-body-horizontal-scroll-viewport': {
      height: '28px!important',
      maxHeight: '28px!important',
      minHeight: '28px!important',
      scrollbarGutter: 'stable',
    },
  },

  '& .ag-theme-alpine .ag-row': {
    borderWidth: '0px',
    borderTop: '1px solid #ABAEB3',
    height: '64px',
    backgroundColor: theme.palette.generic.primary,

    '& *': {
      fontSize: '12px',
    },

    '&:first-of-type': {
      ...(isSROpenedFromQueue && { borderTop: `0px solid ${theme.palette.generic.primary}` }),
    },

    '& .ag-checkbox-input-wrapper input': {
      ...(isSROpenedFromQueue && { cursor: 'pointer' }),
    },

    '::before': {
      background: 'none',
      backgroundColor: 'none',
    },
  },
  '& .ag-header-cell': {
    paddingLeft: '8px',
    paddingRight: '8px',
    backgroundColor: '#F5F6FA',

    '> div:last-child ': {
      width: '100%',
      height: '100%',
    },
    ':hover': {
      backgroundColor: theme.palette.background.bg_hover_alt,
    },
  },
  '.ag-theme-alpine': {
    '.ag-row-hover, .ag-row-focus': {
      ...(!isSROpenedFromQueue && {
        borderTop: '1px solid rgb(197 199 203)',
        boxShadow: 'rgb(48 48 48 / 10%) 0px 11px 2px -9px inset, rgb(48 48 48 / 10%) 0px -11px 2px -9px inset',
        backgroundColor: '#F5F6FA !important',
      }),
    },
    '.ag-row-hover, .ag-row-selected': {
      ...(isSROpenedFromQueue && {
        backgroundColor: '#F5F6FA !important',
      }),
    },
  },
  '&&&& .ag-theme-alpine .ag-header-cell[col-id="id"]': {
    ...(isSROpenedFromQueue && {
      marginLeft: '-12px',
    }),
  },
  '&&&& .ag-theme-alpine .ag-cell': {
    border: '0',
  },
  '.ag-theme-alpine .ag-cell': {
    paddingLeft: '0px',
    paddingRight: '2px',
    '& > div': {
      paddingLeft: '2px',
    },

    '& > div[data-field-type="TIME_REMAINING"], & > div[data-field-type="TIMER"], & > div[data-field-type="SELECT"], & > div[data-field-type="MULTISELECT"]':
      {
        paddingLeft: '8px',
      },
    '& > div[data-field-type="NUMBER"]': {
      paddingLeft: '0px',
      '& > span[data-field-type="text-with-tooltip"]': {
        paddingLeft: '8px',
      },
    },
    '& > div[data-field-type="DATE"], & > div[data-field-type="DATEANDTIME"]': {
      paddingLeft: '0px',
      '& > span[data-field-type="text-with-tooltip"]': {
        paddingLeft: '8px',
      },
    },
    '& > div[data-field-type="TEXT"], & > div[data-field-type="LONGTEXT"]': {
      paddingLeft: '0px',
      '& > span[data-field-type="text-with-tooltip"]': {
        paddingLeft: '4px',
      },
    },

    '&[col-id="checkbox"] > div': {
      paddingLeft: '0.5px',
    },
    '&[col-id="status"] > div, &[col-id="priority"] > div': {
      paddingLeft: '0px',
    },
    '&[col-id="srType"] > div': {
      paddingLeft: '2px!important',
    },
    '&[col-id="primaryCategory"] > div, &[col-id="secondaryCategory"] > div, &[col-id="thirdLevelCategory"] > div': {
      paddingLeft: '7px!important',
    },
    '&[col-id="id"] > div, &[col-id="requestUser.department"] > div, &[col-id="requestUser.company"] > div': {
      paddingLeft: '8px!important',
    },
    '&[col-id="assignee"] > div': {
      paddingLeft: '6px!important',
      '& > div': {
        width: 'unset!important',
        maxWidth: '100%',
        '&> div.assignees > span.assignee-tooltip-wrapper': {
          maxWidth: '100%',
        },
      },
    },
  },
  /* ag-header */

  '& .ag-header': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,

    '& *': {
      fontSize: '13px',
    },

    '& .checkboxClassName': {
      paddingRight: '0',
    },
  },

  '& .ag-column-menu-visible .columnHeaderFilterIcon:before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    bottom: '-2px',
    right: '-2px',
    backgroundColor: theme.palette.blueLabels.bt_light_blue_hover_op,
    zIndex: 0,
    borderRadius: '4px',
  },

  '& .ag-header-active,.ag-column-menu-visible': {
    width: '150px',
    height: '44px',

    backgroundColor: theme.palette.background.bg_hover_alt,
    '& .display-name': {
      color: `${theme.palette.text.title_txt} !important`,
    },
    '.columnHeaderFilterIcon:not(.disableFilterIcon)': {
      position: 'relative',
      display: 'block',
      marginLeft: '4px',
      '& > svg': {
        zIndex: 1,
      },
      '&:hover': {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          bottom: '-2px',
          right: '-2px',
          backgroundColor: theme.palette.blueLabels.bt_light_blue_hover_op,
          zIndex: 0,
          borderRadius: '4px',
        },
      },
    },
    '.columnHeaderFilterIcon.hideFilterIcon': {
      display: 'none',
    },
    '.columnHeaderFilterIcon.disableFilterIcon': {
      position: 'relative',
      display: 'block',
      marginLeft: '4px',
      pointerEvents: 'none',
      '&:hover': {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          bottom: '-2px',
          right: '-2px',
          backgroundColor: 'transparent',
          zIndex: 0,
          borderRadius: '4px',
        },
      },
    },
    '.columnHeaderSortIcon': {
      position: 'relative',
      display: 'block',
      marginLeft: '8px',
      '& > svg': {
        zIndex: 1,
      },
      '&:hover': {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          bottom: '-2px',
          right: '-2px',
          backgroundColor: theme.palette.blueLabels.bt_light_blue_hover_op,
          zIndex: 0,
          borderRadius: '4px',
        },
      },
    },
    ...(!isSROpenedFromQueue && {
      '::before': {
        content: "''",
        width: '4px',
        height: '64px',
        left: 0,
        backgroundColor: theme.palette.generic.sysaid,
        position: 'absolute',
      },
    }),
  },
  '&&& .ag-column-resizing': {
    '.columnHeaderFilterIcon': {
      display: 'none!important',
    },
    '.columnHeaderSortIcon': {
      display: 'none!important',
    },
    '::before': {
      content: "''",
      width: '0!important',
      height: '64px',
      right: 0,
      backgroundColor: theme.palette.generic.sysaid,
      position: 'absolute',
    },
  },

  '&&& .ag-header-cell.deleteClassName': {
    padding: 0,
    display: 'none',
  },

  '&&& .ag-header-cell.checkboxClassName': {
    left: '0 !important',
    paddingLeft: '0px',
    width: 'auto !important',

    '::before': {
      display: 'none',
    },

    '.MuiCheckbox-root': {
      margin: 0,
      marginLeft: '34px',
      borderRadius: 0,
      background: `transparent  url(${checkedOffIcon}) center/contain no-repeat`,
      '&.Mui-checked': {
        background: `transparent  url(${checkedOnIcon}) center/contain no-repeat`,
      },
      '&.MuiCheckbox-indeterminate': {
        background: `transparent  url(${checkedIndeterminateIcon}) center/contain no-repeat`,
      },
      svg: {
        display: 'none',
      },
    },
  },

  '&&& .ag-header-cell.actionButtonsClassName:hover': {
    backgroundColor: theme.palette.generic.primary,

    '::before': {
      display: 'none',
    },
  },

  '.ag-theme-alpine .ag-header-cell-resize': {
    '::after': {
      display: 'none',
    },
    '.columnHeaderFilterIcon': {
      display: 'block',
      marginLeft: '4px',
    },
    '.columnHeaderSortIcon': {
      display: 'block',
      marginLeft: '8px',
    },
    ':hover': {
      content: "''",
      right: 0,
      borderRight: `solid 2px ${theme.palette.text.secondary_txt}`,
      position: 'absolute',
      cursor: 'col-resize',
    },
  },

  '.ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row::after': {
    display: 'none',
  },

  '.ag-pinned-right-header': {
    margin: '0 16px',
    paddingLeft: '16px',
  },

  '& .ag-theme-alpine .ag-header': {
    borderBottom: 'none',

    '& .ag-header-viewport, & .ag-pinned-left-header': {
      borderRight: 'none',
    },

    ...(isSROpenedFromQueue &&
      isScrolled && {
        '& .ag-pinned-left-header': {
          position: 'relative',
          '&::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: -12,
            bottom: 0,
            background: 'linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, rgba(125, 137, 157, 0.1) 100%)',
            zIndex: 1,
            width: '24px',
            pointerEvents: 'none',
          },
        },
      }),

    '& .ag-header-viewport, & .ag-pinned-right-header': {
      border: 'none',
      ...(isSROpenedFromQueue && {
        marginLeft: '-12px',
      }),
    },

    '.ag-header-viewport': {
      marginRight: '-16px',
      background: '#F5F6FA',
    },
  },

  /*end - ag-header-cell */

  '.ag-cell-wrapper': {
    left: '42px',
    gap: '35px',
  },

  /* CHECKBOX */
  '.ag-cell[col-id="checkbox"]': {
    paddingLeft: 0,
    left: '32px !important',
  },
  '.ag-cell[col-id="delete"]': {
    padding: '19px 4px 20px 6px',
    width: '32px !important',
    height: '44px !important',
  },

  '.ag-cell[col-id="title"] > div': {
    width: '100%',
  },

  '.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after': {
    color: '#98adc8',
  },

  '.ag-theme-alpine .ag-checkbox-input-wrapper:focus-within': {
    boxShadow: 'none',
  },
  /*END - CHECKBOX */

  /* ROW SELECTION */
  '.ag-row-hover': {
    '& .delete-sr-action-btn': {
      display: 'flex',
    },
  },

  '.ag-theme-alpine .ag-pinned-left-cols-container': {
    display: 'flex',
    ...(isSROpenedFromQueue && {
      position: 'relative',
    }),
    '.ag-row-selected, .ag-row-focus': {
      ...(!isSROpenedFromQueue && {
        '::before': {
          content: "''",
          backgroundColor: theme.palette.generic.sysaid_default,
          width: '4px',
          height: '64px',
          position: 'absolute',
          /* border-left: 4px solid #699d8c, */
        },
      }),
    },
    ...(isSROpenedFromQueue &&
      isScrolled && {
        '&::before': {
          content: "''",
          position: 'absolute',
          top: 0,
          right: -12,
          bottom: 0,
          background: 'linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, rgba(125, 137, 157, 0.1) 100%)',
          zIndex: 1,
          width: '24px',
          pointerEvents: 'none',
        },
      }),
  },
  ...(isSROpenedFromQueue && {
    '.ag-theme-alpine .ag-center-cols-clipper': {
      marginLeft: '-12px',
    },
  }),
  '.ag-cell-focus': {
    '&[col-id="urgency"], &[col-id="srType"], &[col-id="requestUser"]': {
      '&>.MuiBox-root:not([disabled])': {
        background: theme.palette.background.bg_hover_alt,
      },
    },
    '&[col-id="priority"]': {
      '& .MuiBox-root:not([disabled])': {
        '& .PriorityItem': {
          '.valueClass_0': {
            backgroundColor: theme.palette.blueLabels.bt_light_blue_hover,
          },
          '.valueClass_1': {
            backgroundColor: theme.palette.labels.bt_yellow_hover,
          },
          '.valueClass_2': {
            backgroundColor: theme.palette.labels.bt_red_hover,
          },
        },
      },
    },
    '&[col-id="status"]': {
      '& .MuiBox-root:not([disabled])': {
        '& .StatusItem': {
          '& .valueClass_0': {
            background: theme?.palette?.blueLabels.bt_status_hover,
          },
          '& .valueClass_1, .valueClass_2': {
            background: theme?.palette?.text?.title,
          },
        },
      },
    },
    '&[col-id="primaryCategory"], &[col-id="secondaryCategory"], &[col-id="thirdLevelCategory"]': {
      '& .MuiBox-root:not([disabled])': {
        '& .categoryItem': {
          backgroundColor: '#9FD0E0',
        },
      },
    },
    '&[col-id="dueDate"]': {
      '& .MuiBox-root:not([disabled])': {
        '.MuiLinearProgress-root': {
          backgroundColor: theme.palette.text.disabled,
          opacity: 0.8,
        },
      },
    },
    '&[col-id="assignee"]': {
      '[role="button"]:not([disabled])': {
        '& .MuiAvatar-root': {
          '&::after': {
            content: '""',
            position: 'absolute',
            backgroundColor: theme.palette.hover.avatar,
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          },
        },
      },
    },
  },

  /* right header hover */
  /* Hide right header and spacer */
  '.ag-pinned-right-header, .ag-horizontal-right-spacer': {
    width: '0 !important',
  },

  '.ag-horizontal-right-spacer': {
    height: '24px',
    marginLeft: '10px',
    maxWidth: '0 !important',
    minWidth: '0 !important',
    border: '0 !important',
  },

  /* Add absolute position so that action buttons column will appear on top of other columns. pointer-events: none to pass on mousemove event to behind columns */
  '.ag-pinned-right-cols-container': {
    position: 'absolute !important',
    right: '10px',
    pointerEvents: 'none',
  },
  /* Reset pointer-events so that click can happen on action buttons */
  '.ag-pinned-right-cols-container *': {
    pointerEvents: 'initial',
  },

  /* Hide border of right-cols-container */
  '.ag-pinned-right-cols-container .ag-cell': {
    border: 'none !important',
  },

  /* Show action buttons only for row that is being hovered. For rows which are not being hovered, hide them by setting their width and padding to 0.*/
  '.ag-pinned-right-cols-container .ag-row:not(.ag-row-hover), .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell':
  {
    width: '0 !important',
    padding: '0 !important',
  },

  '.ag-pinned-right-cols-container .ag-row:not(.ag-row-hover), .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .actionsClass':
  {
    display: 'none',
  },

  /* end - right header hover */

  /* end - right header hover */

  '& .ag-theme-alpine .ag-menu': {
    border: 'none',
    borderRadius: '10px',
  },

  '& .ag-theme-alpine .ag-row-last': {
    display: isLastRowWithData ? '' : 'none',
    height: isLastRowWithData ? '' : '250px !important',
    top: !isLastRowWithData && '52px',
  },

  '& .ag-overlay-loading-wrapper': {
    // backgroundColor: 'white',
  },

  '& .ag-full-width-container .ag-row': {
    borderColor: `${theme.palette.background.pages_background} !important`,
  },

  /* ----------------------------------------------
   * Generated by Animista on 2022-3-22 10:40:38
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info.
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation shadow-inset-center
   * ----------------------------------------
   */
  '@-webkit-keyframes shadow-inset-center': {
    '0%': {
      webkitBoxShadow: 'inset 0 0 0 0 rgba(0, 0, 0, 0)',
      boxShadow: 'inset 0 0 0 0 rgba(0, 0, 0, 0)',
    },
    '100%': {
      webkitBoxShadow: 'inset 0 0 14px 0px rgba(0, 0, 0, 0.5)',
      boxShadow: 'inset 0 0 14px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  '@keyframes shadow-inset-center': {
    '0%': {
      webkitBoxShadow: 'inset 0 0 0 0 rgba(0, 0, 0, 0)',
      boxShadow: 'inset 0 0 0 0 rgba(0, 0, 0, 0)',
    },
    '100%': {
      webkitBoxShadow: 'inset 0 0 14px 0px rgba(0, 0, 0, 0.5)',
      boxShadow: 'inset 0 0 14px 0px rgba(0, 0, 0, 0.5)',
    },
  },

  '.ag-empty-row': {
    position: 'relative',
    display: 'none',
  },
}));

import { AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRelatedItemsTables = styled(Box, {
  shouldForwardProp: (prop) => !['rowHeight', 'srRowsCountFitInTable', 'displayed'].includes(prop),
})(({ theme, rowHeight, srRowsCountFitInTable }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',

  '.related-items-title': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
  },

  '.container': {
    height: '100%',
    marginBottom: 0,
    border: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
    borderRadius: '7px',
    // display: !displayed && 'none',
  },

  '.cell, .header-cell': {
    display: 'flex',
    textAlign: 'left',
    width: '15% !important',
    minWidth: '15% !important',
    height: '44px !important',
  },
  '.header-cell': {
    color: theme?.palette?.text?.title_txt,
    '> div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
    },
  },
  '.cell': {
    color: theme?.palette?.text?.txt_default,
  },
  '.table-row': {
    width: '100%',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    position: 'initial !important',
  },
  '.table-body .table-row': {
    width: 'calc(100% - 18px) !important',
    ':hover': {
      backgroundColor: `${theme?.palette?.background?.bg_hover_main} !important`,
      'div.cell': {
        backgroundColor: 'transparent',
      },
      '.titleCell': {
        textDecoration: 'underline',
      },
      '.unlink-item-icon': {
        opacity: '1 !important',
        transition: 'opacity 0.3s',
      },
    },
  },
  '#unlinkItemHeader, .unlinkItemCell': {
    padding: '12px 15px !important',
    width: '6% !important',
    minWidth: '6% !important',
  },
  '#unlinkItemHeader': {
    ':hover': {
      backgroundColor: `${theme?.palette?.grey?.bt_alt_grey_light} !important`,
    },
  },
  '.unlinkItemCell': {
    justifyContent: 'end',
    width: '6% !important',
  },
  '.unlink-item-icon': {
    height: '22px',
    width: '22px',
    minWidth: '22px',
    padding: '3px',
    opacity: '0 !important',
    ':hover': {
      backgroundColor: theme?.palette?.icons?.bt_blue_chip_light_transparent,
    },
  },
  '#titleHeader,.titleCell': {
    width: '49% !important',
    minWidth: '49% !important',
  },
  '#itemNameHeader,.itemNameCell,#ciNameHeader,.ciNameCell': {
    width: '19% !important',
    minWidth: '19% !important',
  },
  '.wrapper >div': {
    borderTopRightRadius: '7px',
    borderTopLeftRadius: '7px',
  },
  '.wrapper>.table-body': {
    borderBottom: `1px solid ${theme?.palette?.grey?.divider_grey_v1} !important`,
    borderBottomLeftRadius: '7px',
    borderBottomRightRadius: '7px',
  },

  '& .table-body': {
    maxHeight: `calc(${srRowsCountFitInTable * rowHeight}px)`,
    '.table-row': {
      '& .cell': {
        height: '63px',
        maxHeight: '63px',
      },

      '.visibleInSelfServicePortalCell': {
        paddingRight: '48px',
      },
    },
  },

  '& .wrapper > .table-body': {
    borderBottom: 'none',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
  },

  '& .wrapper > .table-row': {
    backgroundColor: theme?.palette?.grey?.bt_alt_grey_light,
    '> .header-cell:first-of-type': {
      borderTopLeftRadius: '7px',
    },
    '> .header-cell:last-child': {
      borderTopRightRadius: '7px',
    },
  },

  '& .scroll-bar': {
    marginRight: '-22px !important',
  },

  '& .horizontal-scrollbar': {
    display: 'none',
  },

  '.grid-filters': {
    display: 'none',
  },
  '.MuiAccordion-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  '.MuiCollapse-root': {
    // height: !displayed ? '0px !important' : `calc(${srRowsCountFitInTable * rowHeight + rowHeight}px) !important`,
    height: `calc(${srRowsCountFitInTable * rowHeight + rowHeight}px) !important`,
  },
  '.MuiCollapse-wrapper': {
    height: '100%',
  },
  '.MuiCollapse-wrapperInner': {
    display: 'grid',
    gridTemplateColumns: '100%',
  },
  '.vertical-scrollbar': {
    padding: '0 0 7px !important',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiButtonBase-root': {
    flexDirection: 'row-reverse',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '36px',
    padding: '6px 0px 6px 12px',
    gap: '12px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-root': {
    padding: '6px 0px 6px 7px !important',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  '&.MuiAccordionDetails-root': {
    padding: '0 !important',
    height: '100%',
  },
}));
